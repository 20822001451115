import { axiosInstance } from "../../axios";
const POSTCreatePromoCode = `/api/PromoCode/CreatePromoCode`;
const GETSearch = `/api/PromoCode/SearchPromoCodes/`;
const GETSearchByPage = `/api/PromoCode/SearchPromoCodesByPage`;
const GETValidate = `/api/PromoCode/ValidatePromoCodes/`;
const GETExport = `/api/PromoCode/ExportToExcel/`;
const DELETERemovePromoCode = "api/PromoCode/Delete/"

const search = async function(status, search) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(`${GETSearch + status}/${search}`);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const searchByPage = async function(postData) {
  try {
      const response = await axiosInstance.bhAPIInstance.post(GETSearchByPage, postData);
      let data = parseList(response);
      return data;
  } catch (error) {
      return [];
  }
};

const exportPromoCode = async function(status) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETExport + status);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const validatePrompCode = async function(code, productId) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(`${GETValidate + code}/${productId}`);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const removePromoCode = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.delete(DELETERemovePromoCode + id, null);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const createPromoCode = async function(promo) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTCreatePromoCode, promo);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const parseError = error => {
    if (error.response.data.Errors) {
        let stringMessage = error.response.data.Errors.join(" <br/> ");
        error.response.data.Message += "<br/>" + stringMessage;
        return error.response.data;
    } else {
        return error.response.data;
    }
};
const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

export const promoData = {
    search,
    exportPromoCode,
    createPromoCode,
    validatePrompCode,
    removePromoCode,
    searchByPage
};
